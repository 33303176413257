import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import axios from 'axios';
import { enqueueAxiosError } from 'redux/actions';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import {
  makeStyles,
  FormControlLabel,
  Switch,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import LicenseHistory from '../LicenseHistory';
import Licensee from '../Licensee';
import ChangeHeadTeacher from '../ChangeHeadTeacher';
import EmailField from '../EmailField';
import TeacherStatusBox from '../TeacherStatusBox';
import { units } from 'constants';
import dayjs from 'dayjs';
import BasicDatePicker from '../DatePicker';
import { USER_STATUS } from 'constants';
import { formatLicenseDuration } from 'containers/SuperAdmin/constants';
import LinkedGeneric from '../LinkedGeneric';

const useStyles = makeStyles((theme) => ({
  spacing: {
    margin: theme.spacing(1, 0),
  },
  label: {
    margin: theme.spacing(0, 0),
  },
  genericText: {
    margin: theme.spacing(0, 2.5),
  },
  actions: {
    justifyContent: 'space-between',
  },
  ml: {
    marginLeft: 'auto',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  validity: {
    root: {
      width: '75%',
    },
  },
  licensee: {
    margin: theme.spacing(2, 0),
  },
  bold: {
    fontWeight: 'bold',
  },
  capitalize: {
    textTransform: 'uppercase',
  },
}));

const TIMEOUT = 500;

export default function ChangeLicenseDialog({
  open,
  data,
  handleClose,
  handleConfirm,
}) {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [form, setForm] = useState(data);
  const [schoolUpdate, setSchoolUpdate] = useState({});
  const [userUpdate, setUserUpdate] = useState({});
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [teacherCountError, setTeacherCountError] = useState(false);
  const [studentCountError, setStudentCountError] = useState(false);
  const [studentCountChecking, setStudentCountChecking] = useState(false);
  const [teacherCountChecking, setTeacherCountChecking] = useState(false);

  const [activeTeacherCount, setActiveTeacherCount] = useState(
    data?.teacherLicenses
  );
  const [activeStudentCount, setActiveStudentCount] = useState(
    data?.studentLicenses
  );
  const [expiryDate, setExpiryDate] = useState();

  const [genericStudent, setGenericStudent] = useState({
    username: '',
    password: '',
    createGeneric: false,
  });

  useEffect(() => {
    if (!open) return;
    setSchoolUpdate({});
    setUserUpdate({});
    setEmailError(false);
    setActiveTeacherCount(data?.teacherLicenses);
    setActiveStudentCount(data?.studentLicenses);
    setTeacherCountError(false);
    setStudentCountError(false);
    if (data) {
      setForm(data);
      if (data?.status === 'Activated') {
        setExpiryDate(
          data?.expiryDate ||
            dayjs(data?.activationDate)
              .startOf('day')
              .add(data?.validityPeriod, data?.validityPeriodUnit)
              .toDate()
        );
      }
      if (data?.licenseType === 'School' && data?._id) {
        axios(`/admin/licenses/${data?._id}/activeCount`)
          .then(({ data }) => {
            const count = data?.result;
            setActiveTeacherCount(count?.teachers);
            setActiveStudentCount(count?.students);
          })
          .catch((error) => dispatch(enqueueAxiosError(error)));
      }
    }
  }, [open, data, dispatch]);

  const getLicenseHistory = () => {
    const current = {
      issueDate: data?.issueDate,
      activationDate: data?.activationDate,
      validityPeriod: data?.validityPeriod,
      validityPeriodUnit: data?.validityPeriodUnit,
      expiryDate: expiryDate || data?.expiryDate,
      status: data?.status,
    };
    let history = [current];
    if (data?.history) {
      const historyRecords = data?.history;
      history = [...historyRecords, current];
    }
    return history;
  };

  const onDone = () => {
    handleConfirm({
      form: {
        ...form,
        expiryDate,
      },
      schoolUpdate,
      userUpdate,
      genericStudent,
    });
  };

  const resendWelcomeBackEmail = () => {
    handleConfirm({
      form: {
        ...form,
        expiryDate,
        resendEmail: true,
      },
      schoolUpdate,
      userUpdate,
      genericStudent,
    });
  };
  const resendActivationLink = () => {
    handleConfirm({
      form: {
        ...form,
        expiryDate,
        resendEmail: true,
      },
      schoolUpdate,
      userUpdate,
      genericStudent,
    });
  };

  const onExpiryDateChange = (date) => {
    const activationDate = dayjs(form?.activationDate)?.endOf('day');
    const changedDate = dayjs(date)?.endOf('day');
    setExpiryDate(changedDate?.toDate());
    let validityPeriodUnit = 'days';
    let validityPeriod = changedDate.diff(activationDate, validityPeriodUnit);
    setForm({
      ...form,
      validityPeriod,
      validityPeriodUnit: convertToKebabCase(validityPeriodUnit),
      activationDate: activationDate?.toDate(),
    });
  };

  const onPeriodChange = (e) => {
    const value = e.target.value;
    const unit = form?.validityPeriodUnit;
    const activationDate = dayjs(form?.activationDate)?.endOf('day');
    let expiryDate;
  if (unit === 'Days') {
    expiryDate = activationDate.add(value, 'day');
  } else if (unit === 'Months') {
    expiryDate = activationDate.add(value, 'month');
  } else if (unit === 'Years') {
    expiryDate = activationDate.add(value, 'year');
  }
    setExpiryDate(expiryDate.toDate());
    setForm({
      ...form,
      activationDate: activationDate?.toDate(),
      validityPeriod: value,
    });
  };

  const onPeriodUnitChange = (e) => {
    const unit = e.target.value;
    console.log('unit', unit)
    const value = form?.validityPeriod;
    const activationDate = dayjs(form?.activationDate)?.endOf('day');
    let expiryDate;
  if (unit === 'Days') {
    expiryDate = activationDate.add(value, 'day');
  } else if (unit === 'Months') {
    expiryDate = activationDate.add(value, 'month');
  } else if (unit === 'Years') {
    expiryDate = activationDate.add(value, 'year');
  }
    setExpiryDate(expiryDate.toDate());
    setForm({
      ...form,
      activationDate: activationDate?.toDate(),
      validityPeriodUnit: unit,
    });
  };

  const convertToKebabCase = (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    });
  };

  const handleStudentLicenseChange = (e) => {
    setForm({
      ...form,
      studentLicenses: e.target.value,
      teacherAccess: e.target.value > 0 ? 'full' : 'limited',
    });
    setStudentCountChecking(true);
    setTimeout(() => {
      if (e.target.value < activeStudentCount) {
        setStudentCountError(
          `Student Pool cannot be lower than the quantity of currently active Student Accounts`
        );
        return;
      } else {
        setStudentCountError(false);
      }
      setStudentCountChecking(false);
    }, TIMEOUT);
  };

  const handleTeacherLicenseChange = (e) => {
    setForm({
      ...form,
      teacherLicenses: e.target.value,
    });
    setTeacherCountChecking(true);
    setTimeout(() => {
      if (e.target.value < activeTeacherCount) {
        setTeacherCountError(
          `Teacher Pool cannot be lower than the quantity of currently active Teacher Accounts`
        );
      } else {
        setTeacherCountError(false);
      }
      setTeacherCountChecking(false);
    }, TIMEOUT);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='change-license-dialog-title'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle id='change-license-dialog-title'>{`Edit ${form?.category} License`}</DialogTitle>
      {form?.isGeneric && (
        <Grid container className={styles.genericText}>
          <Typography color='error' className={styles.capitalize}>
            Generic {form?.licenseType} License
          </Typography>
        </Grid>
      )}
      <DialogContent>
        <Grid container direction='column' spacing={2}>
          {form?.licenseType === 'Teacher' && (
            <LinkedGeneric id={form?._id} onGeneric={setGenericStudent} />
          )}
          <Grid
            item
            xs={12}
            sm={12}
            container
            direction='row'
            spacing={2}
            className={styles.spacing}
          >
            <LicenseHistory
              data={getLicenseHistory()}
              period={form?.validityPeriod}
              unit={form?.validityPeriodUnit}
              isAutoRenewal={form?.autoRenew}
              showAnticipated={form.status === 'Activated'}
            />
          </Grid>
          {form?.licenseType === 'School' && (
            <>
              <Grid item xs={12} sm={12} className={styles.spacing}>
                <TextField
                  name='Teacher Licenses'
                  type={'number'}
                  variant='outlined'
                  required
                  fullWidth
                  id='teacherLicenses'
                  label='Teacher Licenses'
                  value={form?.teacherLicenses}
                  onChange={(e) => handleTeacherLicenseChange(e)}
                  error={Boolean(teacherCountError)}
                  helperText={teacherCountError || ''}
                  inputProps={{ min: 1, max: 5 }}
                  InputProps={{
                    endAdornment: teacherCountChecking && (
                      <CircularProgress size={20} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} className={styles.spacing}>
                <TextField
                  name='Student Licenses'
                  type={'number'}
                  variant='outlined'
                  required
                  fullWidth
                  id='studentLicenses'
                  label='Student Licenses'
                  value={form?.studentLicenses}
                  onChange={(e) => handleStudentLicenseChange(e)}
                  inputProps={{ max: 650, min: 1 }}
                  error={Boolean(studentCountError)}
                  helperText={studentCountError || ''}
                  InputProps={{
                    endAdornment: studentCountChecking && (
                      <CircularProgress size={20} />
                    ),
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
        {data?.status === 'Activated' && (
          <Grid item xs={12} sm={12} container direction='row' spacing={2}>
            <Grid item sm={12}>
              <BasicDatePicker
                value={expiryDate}
                setValue={onExpiryDateChange}
                label={'Expiry Date'}
                minDate={form?.issueDate}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                id='validityPeriod'
                name='Validity Period'
                type={'number'}
                variant='outlined'
                required
                fullWidth
                label='Duration'
                value={form?.validityPeriod}
                onChange={(e) => onPeriodChange(e)}
                inputProps={{ min: 1 }}
              />
            </Grid>
            <Grid item sm={6}>
              <FormControl style={styles.select}>
                <InputLabel
                  id='validity-period-label-demo'
                  className={styles.label}
                >
                  Validity Period Unit
                </InputLabel>
                <Select
                  labelId='select-validity-period-label'
                  id='validity-period-select'
                  value={form?.validityPeriodUnit}
                  style={{ width: 200 }}
                  onChange={(e) => onPeriodUnitChange(e)}
                  fullWidth
                >
                  {units.map((option, index) => (
                    <MenuItem value={option} key={index}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography className={styles.bold}>
                License Duration:{' '}
                {formatLicenseDuration(form?.activationDate, expiryDate)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant='caption'>
                Changing the duration will update the License length based on
                its Activation Date.
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          style={{ margin: '20px 0px' }}
          className={styles.spacing}
        >
          <FormControlLabel
            control={
              <Switch
                checked={form?.autoRenew}
                onChange={(e) =>
                  setForm({ ...form, autoRenew: e.target.checked })
                }
                name='autoRenew'
                color='primary'
              />
            }
            label='Auto-Renew License Subscription'
          />
        </Grid>
        <Grid item xs={12} sm={12} className={styles.licensee}>
          <Licensee
            id={form?.licensee}
            type={form?.licenseType}
            isHeadTeacher={form?.licenseType === 'School'}
            textProps={{ variant: 'h6' }}
            variant='button'
            handleSchoolUpdate={setSchoolUpdate}
            handleUserUpdate={setUserUpdate}
          />
        </Grid>
        {!form?.isGeneric && (
          <Grid item xs={12} sm={12} className={styles.spacing}>
            <EmailField
              email={form?.email}
              setEmail={(d) => {
                let update = { ...form, email: d };
                if (!form?.isDomainCompliant) {
                  update = {
                    ...update,
                    domain: d.split('@')[1],
                  };
                }
                setForm(update);
                if (form.licenseType !== 'Student') {
                  setUserUpdate({ ...userUpdate, email: d });
                }
              }}
              disabled={form?.activationDate}
              school={form?.licensee}
              setLoader={setLoading}
              setEmailError={setEmailError}
              forLicense
              licenseData={form}
            />
          </Grid>
        )}
        {form?.licenseType === 'Student' &&
          !form?.activationDate &&
          (form.status === USER_STATUS.ACTIVATING ||
            form.status === 'Approved') && (
            <Typography
              variant='caption'
              color='secondary'
              className={styles.spacing}
            >
              This user’s Account & License are Pending Activation. Changing
              their email address will resend the Activation email to the new
              address.
            </Typography>
          )}
        {form?.licenseType === 'Student' &&
          !form?.activationDate &&
          form.status === USER_STATUS.RESTORING && (
            <Typography
              variant='caption'
              color='secondary'
              className={styles.spacing}
            >
              This user’s Account & License are Pending Reactivation. Changing
              their email address will resend the Welcome Back email to the new
              address.
            </Typography>
          )}
        {form?.licenseType === 'School' && form?.isDomainCompliant && (
          <Grid item xs={12} sm={12} className={styles.spacing}>
            <TextField
              name='Domain'
              variant='outlined'
              required
              fullWidth
              id='domain'
              label='Domain'
              value={form?.domain}
              disabled
              onChange={(e) => {
                setForm({ ...form, domain: e.target.value });
              }}
            />
          </Grid>
        )}
        {form?.licenseType !== 'Student' && form?.activationDate && (
          <Typography
            variant='caption'
            color='secondary'
            className={styles.spacing}
          >
            To update this user’s email address on their Account, please do so
            in the Teachers section.
          </Typography>
        )}
        {form?.licenseType !== 'Student' && !form?.activationDate && (
          <Grid container>
            <Typography
              variant='caption'
              color='error'
              className={styles.spacing}
            >
              If email is changed prior to License being activated, clicking
              “Update” will also resend the relevant activation email to the
              Teacher.
            </Typography>
          </Grid>
        )}
        {form?.licenseType === 'School' && (
          <Grid
            item
            xs={12}
            sm={12}
            style={{ margin: '20px 0px' }}
            className={styles.spacing}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={form.isDomainCompliant}
                  onChange={(e) =>
                    setForm({ ...form, isDomainCompliant: e.target.checked })
                  }
                  name='isDomainCompliant'
                  color='primary'
                />
              }
              label='I confirm that all email addresses under this license should be domain compliant'
            />
          </Grid>
        )}

        {form?.licenseType === 'Student' &&
          form?.activationDate &&
          !form?.isGeneric && (
            <Typography
              variant='caption'
              color='secondary'
              className={styles.spacing}
            >
              Email Address is for internal records & License holder
              communication only. It is not utilized by the student for login
              purposes. To update this user’s email address, please do so in the
              Students section.
            </Typography>
          )}
        {form?.licenseType === 'School' && (
          <Grid item xs={12} sm={12} className={styles.spacing}>
            <ChangeHeadTeacher license={form} handleConfirm={handleClose} />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          style={{ margin: '20px 0px' }}
          className={styles.spacing}
        >
          <FormControlLabel
            control={
              <Switch
                checked={form?.freeTrial}
                onChange={(e) =>
                  setForm({ ...form, freeTrial: e.target.checked })
                }
                name='freeTrial'
                color='primary'
              />
            }
            label='Free Trial'
          />
        </Grid>
        <TeacherStatusBox
          id={form?.licensee}
          license={form}
          isHeadTeacher={form?.licenseType === 'School'}
        />
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Grid container justifyContent='space-between'>
          <Grid item sm={3}>
            <Button onClick={handleClose} color='primary'>
              Cancel
            </Button>
          </Grid>
          <Grid
            item
            sm={
              form.status === 'Activated'
                ? 2
                : form.status === USER_STATUS.ACTIVATING ||
                  form.status === 'Approved'
                ? 6
                : 7
            }
            container
            justifyContent='space-between'
            alignItems={'flex-end'}
          >
            {(form.status === USER_STATUS.RENEWING ||
              form.status === USER_STATUS.RESTORING) &&
              !form?.isGeneric && (
                <Button
                  onClick={resendWelcomeBackEmail}
                  color='primary'
                  variant={'outlined'}
                  endIcon={<Send />}
                >
                  Resend Welcome Back Email
                </Button>
              )}
            {(form.status === USER_STATUS.ACTIVATING ||
              form.status === 'Approved') && (
              <Button
                onClick={resendActivationLink}
                color='primary'
                variant={'outlined'}
                endIcon={<Send />}
              >
                Resend Activation Link
              </Button>
            )}
            <Button
              onClick={onDone}
              color='primary'
              variant={'contained'}
              disabled={
                !form?.email ||
                form?.email === '' ||
                emailError ||
                loading ||
                teacherCountError ||
                studentCountError ||
                (genericStudent.createGeneric &&
                  (!genericStudent.username || !genericStudent.password))
              }
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}