import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  fetchAllSchools,
  fetchAllIndividualTeacherSchools,
  updateSchool,
  updateIndividualSchool,
} from '../../redux/actions/school';
import { fetchHeadTeachers } from 'containers/SuperAdmin/redux/actions/user';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Loader from '../../../../components/Loader';
import EnhancedTable from '../../components/Table';
import PageHeader from '../../components/PageHeader';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import SchoolDialog from '../../components/SchoolDialog';
import TeacherSchoolDialog from 'containers/SuperAdmin/components/TeacherSchoolDialog';
import FilterBar from 'containers/SuperAdmin/components/FilterBar';
import { useQuery } from 'constants';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Schools({ name }) {
  const tab = useQuery('tab');
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [schoolData, setSchoolData] = useState(null);
  const [individualSchoolData, setIndividualSchoolData] = useState(null);
  const [schoolDialog, setSchoolDialog] = useState(false);
  const [individualTeacherSchoolDialog, setIndividualTeacherSchoolDialog] = useState(false);
  const [sort, setSort] = useState({ orderBy: 'name', orderDir: 'asc' });
  const [teacherSort, setTeacherSort] = useState({
    orderBy: 'schoolName',
    orderDir: 'asc',
  });
  const [activeTab, setActiveTab] = useState(tab ? 1 : 0);
  const { individualSchoolId } = useParams();
  const { schools, individualTeacherSchools, fetching, actionLoading } = useSelector(({ admin }) => admin.school);
  const { headTeachers } = useSelector(({ admin }) => admin.users);

  const loadPage = (page, sortOptions = {}) => {
    dispatch(fetchAllSchools({ page, limit: schools.limit, ...sortOptions }));
    setSort(sortOptions);
  };
  const loadIndividualSchoolsPage = (page, sortOptions = {}) => {
    dispatch(
      fetchAllIndividualTeacherSchools({
        page,
        limit: individualTeacherSchools.limit,
        ...sortOptions,
      }),
    );
    setTeacherSort(sortOptions);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const headCells = [
    { id: 'name', label: 'Name' },
    {
      id: 'address',
      label: 'Location',
      formatter: (row) => {
        return !row?.address || row.address.trim() === '' ? 'Not Provided' : row?.address;
      },
    },
    {
      id: 'email',
      label: 'Head Teacher',
      formatter: (row) => {
        const teacher = headTeachers.find((x) => x.school === row._id);
        if (teacher) {
          return `${[teacher.firstName, teacher.lastName].join(' ')}`;
        } else {
          return 'N/A';
        }
      },
    },
    {
      id: 'teachers',
      label: 'Teachers',
      formatter: (row) => (
        <Button
          color="primary"
          size="small"
          style={{ textTransform: 'none' }}
          onClick={() => history.push('/superadmin/schools/' + row._id + '/teachers?isArchived=false')}
        >
          {row.teacherCount}
        </Button>
      ),
    },
    {
      id: 'classes',
      label: 'Classes',
      formatter: (row) => (
        <Button
          color="primary"
          size="small"
          style={{ textTransform: 'none' }}
          onClick={() => history.push('/superadmin/schools/' + row._id + '/classes')}
        >
          {row.classCount || 'N/A'}
        </Button>
      ),
    },
    {
      id: 'students',
      label: 'Students',
      formatter: (row) => (
        <Button
          color="primary"
          size="small"
          style={{ textTransform: 'none' }}
          onClick={() => history.push('/superadmin/schools/' + row._id + '/students?isArchived=false')}
        >
          {row.studentCount || 'N/A'}
        </Button>
      ),
    },
    {
      id: 'actions',
      label: 'Actions',
      actions: [
        {
          label: 'Edit',
          action: (r) => {
            setSchoolData(r);
            setSchoolDialog(true);
          },
          icon: <EditIcon />,
          disabled: () => false,
        },
      ],
    },
  ];

  const individualTeacherHeadCells = [
    {
      id: 'schoolName',
      label: 'Name',
      formatter: (row) => {
        const schoolName = row.schoolName;
        return schoolName ? schoolName : 'Not Provided';
      },
    },
    {
      id: 'schoolAddress',
      label: 'Location',
      formatter: (row) => {
        const schoolAddress = row.schoolAddress;
        return schoolAddress ? schoolAddress : 'Not Provided';
      },
    },
    {
      id: 'email',
      label: `${activeTab === 0 ? 'Head Teacher' : 'Individual Teacher'}`,
      formatter: (row) => (
        <Button
          color="primary"
          size="small"
          style={{ textTransform: 'none' }}
          onClick={() => {
            history.push('/superadmin/schools/' + row._id + '/teacher');
          }}
        >
          {[row.firstName, row.lastName].join(' ')}
        </Button>
      ),
    },
    {
      id: 'classes',
      label: 'Classes',
      formatter: (row) => (
        <Button
          color="primary"
          size="small"
          style={{ textTransform: 'none' }}
          onClick={() => history.push('/superadmin/teacher/' + row._id + '/classes')}
        >
          {row.classCount || 'N/A'}
        </Button>
      ),
    },
    {
      id: 'students',
      label: 'Students',
      formatter: (row) => (
        <Button
          color="primary"
          size="small"
          style={{ textTransform: 'none' }}
          onClick={() => {
            if (row.isArchived === 'archived') {
              history.push(`/superadmin/teacher/${row._id}/students?isArchived=true`, {
                from: '/superadmin/teacher?isArchived=true',
              });
            } else {
              history.push('/superadmin/teacher/' + row._id + '/students');
            }
          }}
        >
          {row?.studentCount || 'N/A'}
        </Button>
      ),
    },
    {
      id: 'actions',
      label: 'Actions',
      actions: [
        {
          label: 'Edit',
          action: (r) => {
            setIndividualSchoolData(r);
            setIndividualTeacherSchoolDialog(true);
          },
          icon: <EditIcon />,
          disabled: () => false,
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch(fetchAllSchools());
    dispatch(fetchHeadTeachers());
    if (individualSchoolId) {
      console.log('individual school id', individualSchoolId);
      setActiveTab(1);
      dispatch(fetchAllIndividualTeacherSchools({ _id: individualSchoolId }));
    } else {
      dispatch(fetchAllIndividualTeacherSchools());
    }
  }, [dispatch, individualSchoolId]);

  return (
    <div className={classes.root}>
      {(fetching || actionLoading) && <Loader fetching={fetching || actionLoading} />}
      {/* Conditionally render content based on the active tab */}
      <PageHeader title={name} onClearFilter={() => history.push('/superadmin/schools')} />
      {/* Tabs Section */}
      <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered>
        <Tab label="Schools" />
        <Tab label="Individual Teacher Schools" />
      </Tabs>
      {activeTab === 0 && schools && schools.docs.length > 0 && (
        <>
          <FilterBar
            enableTopics={false}
            enableGlobal
            selectedPage="School"
            onSearch={(d) => dispatch(fetchAllSchools({ search: d }))}
          />
          <Grid container direction="row" justifyContent="flex-start" spacing={2}>
            <Grid item sm={12} xs={12}>
              <EnhancedTable
                data={schools}
                headCells={headCells}
                handlePage={loadPage}
                orderField={sort.orderBy}
                orderDirection={sort.orderDir}
                handleOrder={(d) => loadPage(schools.page, d)}
              />
            </Grid>
          </Grid>
        </>
      )}

      {activeTab === 1 && individualTeacherSchools && individualTeacherSchools.docs.length > 0 && (
        <>
          <FilterBar
            enableTopics={false}
            enableGlobal
            selectedPage="School"
            onSearch={(d) => {
              let update = {
                search: d,
              };
              if (individualSchoolId) {
                update._id = individualSchoolId;
              }
              dispatch(fetchAllIndividualTeacherSchools({ search: d }));
            }}
          />
          <Grid container direction="row" justifyContent="flex-start" spacing={2}>
            <Grid item sm={12} xs={12}>
              <EnhancedTable
                data={individualTeacherSchools}
                headCells={individualTeacherHeadCells}
                handlePage={loadIndividualSchoolsPage}
                orderField={teacherSort.orderBy}
                orderDirection={teacherSort.orderDir}
                handleOrder={(d) => loadIndividualSchoolsPage(individualTeacherSchools.page, d)}
              />
            </Grid>
          </Grid>
        </>
      )}

      {schoolData && (
        <SchoolDialog
          open={schoolDialog}
          edit={true}
          data={schoolData}
          handleClose={() => {
            setSchoolDialog(false);
            setSchoolData(null);
          }}
          handleConfirm={(data) => {
            let { name, address } = data?.school;
            dispatch(updateSchool(schoolData._id, { name, address }));
            setSchoolDialog(false);
            setSchoolData(null);
          }}
          headTeacherData={headTeachers?.find((x) => x.school === schoolData?._id)}
        />
      )}
      {individualSchoolData && (
        <>
          <TeacherSchoolDialog
            open={individualTeacherSchoolDialog}
            edit={true}
            data={individualSchoolData}
            handleClose={() => {
              setIndividualTeacherSchoolDialog(false);
              setIndividualSchoolData(null);
            }}
            handleConfirm={(data) => {
              let { schoolName, schoolAddress } = data?.teacher;
              dispatch(
                updateIndividualSchool(individualSchoolData._id, {
                  schoolName,
                  schoolAddress,
                }),
              );
              setIndividualTeacherSchoolDialog(false);
              setIndividualSchoolData(null);
            }}
            individualTeacherData={individualSchoolData}
          />
        </>
      )}
    </div>
  );
}
