import clsx from 'clsx';
import './aprender.css';
import React, { useState, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import { aprenderColorMap } from '../../constants';
import { Avatar, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  imageContainer: ({ width, isPressed }) => ({
    position: 'relative',
    display: 'inline-block',
    width,
    height: 'auto',
    transform: isPressed ? 'scale(0.8)' : 'scale(1)',
    WebkitTransform: isPressed ? 'scale(0.8)' : 'scale(1)',
    MozTransform: isPressed ? 'scale(0.8)' : 'scale(1)',
    OTransform: isPressed ? 'scale(0.8)' : 'scale(1)',
    msTransform: isPressed ? 'scale(0.8)' : 'scale(1)',
    [theme.breakpoints.between('sm', 'md')]: {
      width: width === 220 ? '180px' : width,
    },
    '&:active': {
      cursor: 'pointer',
    },
    transition: 'all 0.3s ease-in-out',
    WebkitTransition: 'all 0.3s ease-in-out',
    MozTransition: 'all 0.3s ease-in-out',
    OTransition: 'all 0.3s ease-in-out',
    overflow: 'hidden',
  }),
  topImage: ({
    width,
    isRound,
    percentage = 0.85,
    horizontalPercentage = 50,
    verticalPercentage = 50,
    topLeftRadius = 50,
    topRightRadius = 50,
    bottomLeftRadius = 50,
    bottomRightRadius = 50,
    imagePadding,
  }) => ({
    position: 'absolute',
    left: '50%',
    width: `calc(${width}px * ${percentage})`,
    borderTopLeftRadius: isRound ? `${topLeftRadius}%` : 0,
    borderTopRightRadius: isRound ? `${topRightRadius}%` : 0,
    borderBottomLeftRadius: isRound ? `${bottomLeftRadius}%` : 0,
    borderBottomRightRadius: isRound ? `${bottomRightRadius}%` : 0,
    height: 'auto',
    transform: `translate(-${horizontalPercentage}%, -${verticalPercentage}%)`,
    maxHeight: '100%',
    zIndex: '2',
    objectFit: 'contain',
    [theme.breakpoints.between('sm', 'md')]: {
      width: `calc(${width === 220 ? 180 : width}px * ${percentage})`,
    },
    ...(imagePadding && {
        padding: imagePadding,
    }),
  }),
  focusVisible: {
    outline: 'none',
    borderRadius: '50%',
  },
}));

export const ColoredTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#1052BA',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: theme.palette.common.white,
    '&:before': {
      boxShadow: theme.shadows[1],
    },
  },
}))(Tooltip);

export default function AprenderButton({
  color,
  image,
  title,
  width = 200,
  isRound,
  percentage = 0.85,
  customDimensions,
  horizontalPercentage = 50,
  verticalPercentage = 50,
  topRightRadius = 50,
  topLeftRadius = 50,
  bottomRightRadius = 50,
  bottomLeftRadius = 50,
  mini,
  size,
  tooltip = false,
  margin,
  imagePadding,
  ...props
}) {
  const [isPressed, setIsPressed] = useState(false);
  const buttonColor = aprenderColorMap[color] || 'blue';
  const classes = useStyles({
    width,
    isPressed,
    isRound,
    percentage,
    horizontalPercentage,
    verticalPercentage: verticalPercentage,
    topRightRadius,
    topLeftRadius,
    bottomRightRadius,
    bottomLeftRadius,
    imagePadding,
  });
  const isSVG = image.endsWith('.svg');
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const [isLoading, setIsLoading] = useState(!Boolean(isSVG && isSafari));
  const timeRef = useRef();

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handlePress = () => {
    setIsPressed(true);
    // add a time out to prevent the button from being stuck in the pressed state

    if (timeRef.current) {
      clearTimeout(timeRef.current);
    }

    timeRef.current = setTimeout(() => {
      setIsPressed(false);
    }, 375);
  };

  if (tooltip) {
    return (
      <ColoredTooltip title={title} placement="top" arrow>
        <ButtonBase
          focusRipple={false}
          disableRipple={true}
          className={clsx(
            classes.imageContainer,
            buttonColor.toLowerCase(),
            'aprender-button',
            mini && 'mini',
            size && `size-${size}`,
          )}
          focusVisibleClassName={classes.focusVisible}
          onClick={props.onClick}
          onMouseDown={handlePress}
          style={{
            ...(margin && {
              margin,
            }),
            ...(customDimensions && {
              width,
              height: width,
              ...customDimensions,
            }),
          }}
          aria-label={title}
          aria-labelledby="aprender-button"
        >
          {isLoading && <CircularProgress size={mini ? 20 : 40} className={classes.topImage} />}
          {(!isSVG || !isSafari) && (
            <Avatar
              src={image}
              className={classes.topImage}
              imgProps={{
                onLoad: handleImageLoad,
                onError: handleImageLoad,
                alt: title,
                loading: 'lazy',
                decoding: 'async',
                'aria-label': 'button',
                'aria-hidden': 'true',
                height: width,
                width,
              }}
              style={isLoading ? { visibility: 'hidden' } : {}}
            />
          )}
          {isSVG && isSafari && (
            <object data={image} aria-label="button" type="image/svg+xml" className={classes.topImage} />
          )}
        </ButtonBase>
      </ColoredTooltip>
    );
  }

  return (
    <ButtonBase
      focusRipple={false}
      disableRipple={true}
      className={clsx(
        classes.imageContainer,
        buttonColor.toLowerCase(),
        'aprender-button',
        mini && 'mini',
        size && `size-${size}`,
      )}
      focusVisibleClassName={classes.focusVisible}
      onClick={props.onClick}
      onMouseDown={handlePress}
      style={{
        ...(customDimensions && {
          width,
          height: width,
          ...customDimensions,
        }),
      }}
      aria-label={title}
      aria-labelledby="aprender-button"
    >
      {isLoading && <CircularProgress size={mini ? 20 : 40} />}
      {(!isSVG || !isSafari) && (
        <Avatar
          src={image}
          className={classes.topImage}
          imgProps={{
            onLoad: handleImageLoad,
            onError: handleImageLoad,
            alt: title,
            loading: 'lazy',
            decoding: 'async',
            'aria-label': 'button',
            'aria-hidden': 'true',
            height: width,
            width,
          }}
          style={isLoading ? { visibility: 'hidden' } : {}}
        />
      )}
      {isSVG && isSafari && (
        <object data={image} aria-label="button" type="image/svg+xml" className={classes.topImage} />
      )}
    </ButtonBase>
  );
}
