import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addWorkbook } from '../../../redux/actions/workbook';

import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const initialWorkbook = {
  order: 0,
  title: 'Workbook Title',
  startPage: 1,
  content: [],
};

export default function NewWorkbook({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actionLoading } = useSelector(({ admin }) => admin.workbook);
  const [workbookData, setWorkbookData] = useState(initialWorkbook);

  const handleSave = (d) => {
    setWorkbookData(d);
    dispatch(addWorkbook(d));
    history.push('/superadmin/workbooks');
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      <Form
        initialData={workbookData}
        onSave={handleSave}
        multipleField={'content'}
        multipleFieldTitle={'Workbook Pages'}
        dropZoneParams={{
          maxFiles: 250,
          multiple: true,
        }}
        numberFields={['startPage']}
        titleField={'title'}
        orderField={false}
      />
    </div>
  );
}
