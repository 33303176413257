import { combineReducers } from 'redux';

import users from './users';
import conversar from './conversar';
import aprender from './aprender';
import cantar from './cantar';
import practicar from './practicar';
import topic from './topic';
import leer from './leer';
import grade from './grade';
import upload from './upload';
import manual from './manual';
import lesson from './lesson';
import group from './group';
import setting from './setting';
import school from './school';
import resource from './resource';
import video from './video';
import license from './license';
import student from './student';
import guide from './guide';
import workbook from './workbook';
import email from './email';

const admin = combineReducers({
  users,
  email,
  conversar,
  aprender,
  cantar,
  practicar,
  topic,
  leer,
  grade,
  upload,
  manual,
  lesson,
  group,
  setting,
  school,
  resource,
  video,
  license,
  student,
  guide,
  workbook,
});

export { admin };
