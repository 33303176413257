import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {
  TextField,
  InputAdornment,
  Hidden,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ViewListIcon from '@material-ui/icons/ViewList';

const SearchTextField = withStyles({
  root: {
    backgroundColor: '#fcfcfc',
    color: '#2f3133',
    borderRadius: '1.5rem',
    '&:hover': {
      backgroundColor: '#f0f0f2',
      boxShadow: '#a1a1a1 0px 3px 8px 1px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none !important',
        '&:hover': {
          border: '1px solid #f0f0f2',
        },
      },
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#2f3133',
    },
    '&.Mui-focused fieldset': {
      backgroundColor: '#fffff',
      boxShadow: '#a1a1a1 0px 3px 13px 1px',
      border: '1px solid #f0f0f2',
    },
  },
})(TextField);

const useStyles = makeStyles(() => ({
  filterButton: {
    borderRadius: 20,
    marginLeft: 10,
    textTransform: 'none',
  },
}));

export default function PageHeader({
  title,
  onSearch,
  options,
  disableSearch = true,
  back = false,
  handleBack,
  onClearFilter,
  ...props
}) {
  const classes = useStyles();
  const [search, setSearch] = useState(false);
  const [moreOptions, setMoreOptions] = useState(false);
  const history = useHistory();
  const isMatched = useRouteMatch([
    '/superadmin/teachers/:teacher/classes',
    '/superadmin/teacher/:teacher/classes',
    '/superadmin/teachers/:teacher/students',
    '/superadmin/teacher/:teacher/students',
    '/superadmin/grades/:grade/students',
    '/superadmin/schools/:school/students',
    '/superadmin/schools/:school/classes',
    '/superadmin/schools/:school/teachers',
    '/superadmin/schools/:individualTeacherShool/teacher',
  ]);
  return (
    <Grid container direction={'column'} {...props}>
      <Grid
        item
        container
        direction={'row'}
        justifyContent='space-between'
        alignItems='center'
        style={{ marginBottom: '1rem' }}
        spacing={0}
      >
        <Grid item container xs={5} sm={6} md={6}>
          <Hidden smDown>
            {back && (
              <IconButton
                size='small'
                onClick={handleBack ? handleBack : () => history.goBack()}
                style={{ marginRight: 10 }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography variant='h4' noWrap>
              {title}
            </Typography>
            {isMatched && (
              <Button
                onClick={onClearFilter}
                endIcon={<CloseIcon />}
                variant='contained'
                color='primary'
                className={classes.filterButton}
                size='small'
              >
                Cancel Filters
              </Button>
            )}
          </Hidden>
          <Hidden smUp>
            <Typography variant='h6' noWrap>
              {title}
            </Typography>
          </Hidden>
        </Grid>
        <Grid
          item
          container
          direction='row'
          xs={7}
          sm={6}
          md={6}
          justifyContent={'flex-end'}
          alignItems='center'
        >
          {!search && !disableSearch && (
            <Button
              disabled={moreOptions}
              endIcon={<SearchIcon style={{ color: '#bdbdbd' }} />}
              onClick={() => setSearch(!search)}
            >
              Search
            </Button>
          )}
          {search && (
            <Hidden smDown>
              <Grid
                container
                item
                style={{ height: '4.5rem', width: 'fit-content' }}
                alignItems='center'
              >
                <SearchTextField
                  variant='outlined'
                  autoFocus
                  placeholder='Search here'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position='start'
                        style={{ border: 'none' }}
                      >
                        <SearchIcon style={{ color: 'lightgrey' }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position='end'
                        style={{ border: 'none', cursor: 'pointer' }}
                      >
                        <CloseIcon
                          style={{ color: 'black' }}
                          onClick={() => {
                            setSearch(false);
                            onSearch('');
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => onSearch(e.target.value)}
                />
              </Grid>
            </Hidden>
          )}
          {options && <Hidden smDown>{options}</Hidden>}
          {options && (
            <Hidden smUp>
              <Button
                icon={
                  moreOptions ? (
                    <CloseIcon style={{ color: '#2196f3' }} />
                  ) : (
                    <ViewListIcon style={{ color: '#2196f3' }} />
                  )
                }
                onClick={() => setMoreOptions(!moreOptions)}
                text='More Options'
              />
            </Hidden>
          )}
        </Grid>
        <Hidden smUp>
          <Grid item xs={12} container justifyContent='center'>
            {search && (
              <SearchTextField
                fullWidth
                variant='outlined'
                placeholder='Search here'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' style={{ border: 'none' }}>
                      <SearchIcon style={{ color: 'lightgrey' }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      style={{ border: 'none', cursor: 'pointer' }}
                    >
                      <CloseIcon
                        style={{ color: 'black' }}
                        onClick={() => {
                          setSearch(false);
                          onSearch('');
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => onSearch(e.target.value)}
              />
            )}
            {moreOptions && options}
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
}
