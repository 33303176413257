import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllEmailReports } from 'containers/SuperAdmin/redux/actions/email';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Loader from '../../../../components/Loader';
import EnhancedTable from '../../components/Table';
import PageHeader from '../../components/PageHeader';
import dayjs from 'dayjs';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EmailDialog from 'containers/SuperAdmin/components/EmailDialog';
import { emailTypes } from 'constants';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function EmailTracking({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sort, setSort] = useState({ orderBy: 'name', orderDir: 'asc' });
  const [emailDialog, setEmailDialog] = useState(false);
  const [emailDialogTitle, setEmailDialogTitle] = useState('');
  const [emailHtml, setEmailHtml] = useState('');
  const { emailReports, fetching } = useSelector(({ admin }) => admin.email);
  const loadPage = (page, sortOptions = {}) => {
    dispatch(
      fetchAllEmailReports({ page, limit: emailReports.limit, ...sortOptions })
    );
    setSort(sortOptions);
  };

  useEffect(() => {
    dispatch(fetchAllEmailReports());
  }, [dispatch]);

  const getEmailType = (subject) => {
    for (let i in emailTypes) {
      if (emailTypes[i].includes(subject)) {
        return i;
      }
    }
  };

  const formatKey = (key) => {
    return key
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase());
  };
  const handlePreview = (row) => {
    setEmailDialog(true);
    setEmailHtml(row.html);
    const key = getEmailType(row.subject);
    const title = `${formatKey(key)} ${row?.resend ? ' (Resend)' : ''}`;
    setEmailDialogTitle(title);
  };

  const headCells = [
    {
      id: 'createdAt',
      label: 'Date Sent',
      formatter: (row) => {
        return dayjs(emailReports.docs[0].createdAt).format('DD/MM/YYYY');
      },
    },
    { id: 'triggeredBy', label: 'Triggered By' },
    { id: 'status', label: 'Status' },
    {
      id: 'subject',
      label: 'Subject',
      formatter: (row) => {
        const key = getEmailType(row.subject);
        return `${formatKey(key)} ${row?.resend ? ' (Resend)' : ''}`;
      },
    },
    { id: 'userType', label: 'User Type' },
    {
      id: 'school',
      label: 'School',
      formatter: (row) => {
        return row?.school ? row?.school : 'N/A';
      },
    },
    { id: 'accountOwner', label: 'Account Owner' },
    { id: 'email', label: 'Email Address' },
    {
      id: 'preview',
      label: 'Preview',
      formatter: (row) => {
        return <VisibilityIcon onClick={() => handlePreview(row)} />;
      },
    },
  ];
  return (
    <div className={classes.root}>
      {fetching ? (
        <Loader fetching={fetching} />
      ) : (
        <>
          <PageHeader
            onSearch={(search) => dispatch(fetchAllEmailReports({ search }))}
            title={name}
          />
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            spacing={2}
          >
            <Grid item sm={12} xs={12}>
              {emailReports && emailReports?.docs?.length > 0 ? (
                <EnhancedTable
                  data={emailReports}
                  headCells={headCells}
                  handlePage={loadPage}
                  orderField={sort.orderBy}
                  orderDirection={sort.orderDir}
                  handleOrder={(d) => loadPage(emailReports.page, d)}
                />
              ) : (
                <p>No email reports found.</p>
              )}
            </Grid>
          </Grid>
        </>
      )}
      {emailDialog && (
        <EmailDialog
          open={emailDialog}
          handleClose={() => setEmailDialog(false)}
          html={emailHtml}
          title={emailDialogTitle}
        />
      )}
    </div>
  );
}
