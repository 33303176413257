import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  EMAILLIST_ATTEMPT,
  EMAIL_ERROR,
  FETCHEMAIL_SUCCESS,
} from '../constants.js';

const emailUrl = 'admin/email-reports';

export const fetchAllEmailReports = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(EMAILLIST_ATTEMPT));
      const body = await axios.get(`${emailUrl}`, { params });
      dispatch(successAction(FETCHEMAIL_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(EMAIL_ERROR, err));
    }
  };
  return thunk;
};