import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';


export default function TeacherSchoolDialog({
  open,
  handleClose,
  handleConfirm,
  data,
  edit = false,
  individualTeacherData = null,
}) {
  const [form, setForm] = useState({ schoolName: '', schoolAddress: '' });
  const [teacherData, setTeacherData] = useState(individualTeacherData);
  useEffect(() => {
    if (!open) return;
    if (data) {
      setForm(data);
    }
    if (individualTeacherData) {
      setTeacherData(individualTeacherData);
    } else {
      setTeacherData(null);
    }
  }, [open, edit, data, individualTeacherData]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      maxWidth='sm'
    >
      <DialogTitle id='form-dialog-title'>School</DialogTitle>
      <DialogContent>
        <Grid container direction='column' spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              autoComplete='schoolName'
              name='schoolName'
              variant='outlined'
              required
              fullWidth
              id='schoolName'
              label='School Name'
              autoFocus
              value={form.schoolName}
              onChange={(e) => setForm({ ...form, schoolName: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              variant='outlined'
              required
              fullWidth
              id='schoolAddress'
              label='School Address'
              name='schoolAddress'
              autoComplete='schoolAddress'
              value={form.schoolAddress}
              onChange={(e) => setForm({ ...form, schoolAddress: e.target.value })}
            />
          </Grid>
          <Divider />
          {teacherData && (
            <Grid item xs={12} sm={12} container spacing={2}>
              <Grid item xs={12} sm={12} container direction={'row'}>
                <Typography variant='h5'>Individual Teacher</Typography>
              </Grid>
              <Grid item xs={12} sm={12} container direction={'row'}>
                <Grid item xs={12} sm={3}>
                  <Typography>Name</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {[teacherData?.firstName, teacherData?.lastName].join(' ')}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} container direction={'row'}>
                <Grid item xs={12} sm={3}>
                  <Typography>Email</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {teacherData?.email}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={() =>
            handleConfirm({ teacher: form})
          }
          color='primary'
          variant={'contained'}
          disabled={
            !teacherData ||
            teacherData?.firstName === '' ||
            teacherData?.lastName === '' ||
            teacherData?.email === '' ||
            form?.schoolName === ''
          }
        >
          {edit ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
