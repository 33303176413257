/**
 * * Users Reducer
 */

import {
  SCHOOLACTION_ATTEMPT,
  SCHOOLLIST_ATTEMPT,
  SCHOOL_ERROR,
  FETCHALLSCHOOLS_SUCCESS,
  FETCHALLINDIVIDUALSCHOOLS_SUCCESS,
  UPDATEINDIVIDUALSCHOOL_SUCCESS,
  FETCHALLARCHIVEDSCHOOLS_SUCCESS,
  FETCHSCHOOL_SUCCESS,
  ADDSCHOOL_SUCCESS,
  UPDATESCHOOL_SUCCESS,
  DELETESCHOOL_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  schools: null,
  archivedSchools: null,
  school: null,
};

export default function school(state = initialState, action) {
  switch (action.type) {
    case SCHOOLLIST_ATTEMPT:
      return { ...state, fetching: true };
    case SCHOOLACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case SCHOOL_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case FETCHALLSCHOOLS_SUCCESS:
      return { ...state, fetching: false, schools: action.payload.data };
    case FETCHALLINDIVIDUALSCHOOLS_SUCCESS:
      return { ...state, fetching: false, individualTeacherSchools: action.payload.data };
    case FETCHALLARCHIVEDSCHOOLS_SUCCESS:
      return {
        ...state,
        fetching: false,
        archivedSchools: action.payload.data,
      };
    case FETCHSCHOOL_SUCCESS:
      return { ...state, fetching: false, school: action.payload.school };
    case ADDSCHOOL_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        schools: state.schools
          ? {
              ...state.schools,
              docs: [...state.schools.docs, action.payload.school],
            }
          : { docs: [action.payload.school] },
      };
    case UPDATESCHOOL_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        schools: {
          ...state.schools,
          docs: state.schools.docs.map((x) => {
            if (x._id === action.payload.school._id) {
              return action.payload.school;
            } else {
              return x;
            }
          }),
        },
      };
    case UPDATEINDIVIDUALSCHOOL_SUCCESS:
      console.log('inside', state, action.payload)
      return {
        ...state,
        actionLoading: false,
        individualTeacherSchools: {
          ...state.individualTeacherSchools,
          docs: state.individualTeacherSchools.docs.map((x) => {
            if (x._id === action.payload.user._id) {
              return action.payload.user;
            } else {
              return x;
            }
          }),
        },
      };
    case DELETESCHOOL_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        schools: {
          ...state.schools,
          docs: state.schools.docs.filter((x) => x._id !== action.payload.id),
        },
      };
    default:
      return state;
  }
}
