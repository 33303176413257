/**
 * * Users Reducer
 */

import {
  EMAILLIST_ATTEMPT,
  EMAIL_ERROR,
  FETCHEMAIL_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  emailReports: null,
};

export default function email(state = initialState, action) {
  switch (action.type) {
    case EMAILLIST_ATTEMPT:
      return { ...state, fetching: true };
    case EMAIL_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case FETCHEMAIL_SUCCESS:
      return { ...state, fetching: false, emailReports: action.payload.data };
    default:
      return state;
  }
}
