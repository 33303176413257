import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import {
  CircularProgress,
  makeStyles,
  Typography,
  Switch,
  FormControlLabel,
  TextField,
  colors,
  InputAdornment,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { enqueueSnackbar } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { removeLicenseFromTable } from 'containers/SuperAdmin/redux/actions/license';
import PasswordField from 'components/PasswordField';
import { CheckCircle, WarningRounded } from '@material-ui/icons';
import { validUsername } from 'utils';
import { findUsername } from 'redux/teachers/actions';
import { SEARCH_TIMEOUT, ERRORS } from 'constants';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 1),
  },
  spacing: {
    margin: theme.spacing(1, 0),
  },
  caption: {
    margin: theme.spacing(1),
  },
}));

const LinkedGeneric = ({ id, onGeneric }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const timer = useRef(null);

  const [student, setStudent] = useState({});
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(false);
  const [createGeneric, setCreateGeneric] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { message, status, loading: usernameLoading } = useSelector(({ teachers }) => teachers);

  useEffect(() => {
    if (!id) return;
    axios
      .get(`/admin/licenses/${id}/generic`)
      .then((res) => {
        setStudent(res.data);
        setCreateGeneric(!!res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || 'Something went wrong');
      });
  }, [id]);

  const deleteStudent = () => {
    const id = student?._id;
    setDeleting(true);
    axios
      .delete(`/admin/licenses/generic/${id}`)
      .then(() => {
        setStudent(null);
        setDeleting(false);
        dispatch(removeLicenseFromTable(id));
        dispatch(
          enqueueSnackbar({
            message: 'Generic Student deleted successfully',
            options: { variant: 'success' },
          })
        );
      })
      .catch((err) => {
        setDeleting(false);
        setError(err?.response?.data?.message || 'Something went wrong');
      });
  };

  const handleGeneric = (value) => {
    setCreateGeneric(value);
    if (!value && student) {
      deleteStudent();
    }
    onGeneric({
      createGeneric: value,
      username,
      password,
    });
  };

  const activateTimer = (e) => {
    const valid = validUsername(e.target.value);
    if (!valid) {
      setError(true);
      return;
    }
    setError(false);
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      dispatch(findUsername(e.target.value));
    }, SEARCH_TIMEOUT);
  };

  if (loading)
    return (
      <Grid container justifyContent='center' alignItems='center'>
        <CircularProgress color='primary' size={20} />
      </Grid>
    );

  return (
    <Grid container alignItems='center' className={classes.root}>
      <Grid item xs={12} sm={12} className={classes.spacing}>
        {deleting ? (
          <CircularProgress size={20} color='primary' />
        ) : (
          <FormControlLabel
            control={
              <Switch
                checked={createGeneric}
                onChange={(e) => handleGeneric(e.target.checked)}
                name='createGeneric'
                color='primary'
              />
            }
            label={
              createGeneric && student
                ? `Generic Student License (${student?.username})`
                : 'Create and Link a Generic Student License'
            }
          />
        )}
      </Grid>
      {createGeneric && !student && (
        <>
          <Grid item xs={12} sm={12} className={classes.spacing}>
            <TextField
              error={status > 200 || error === true}
              helperText={error ? ERRORS.USERNAME : status > 200 ? message : ''}
              variant='outlined'
              required
              fullWidth
              id='username'
              label='Username'
              name='username'
              value={username}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setUsername(e.target.value.trim());
                onGeneric({
                  createGeneric,
                  username: e.target.value.trim(),
                  password,
                });
              }}
              onKeyUp={activateTimer}
              InputProps={{
                endAdornment: error ? (
                  <InputAdornment>
                    {usernameLoading && (
                      <CircularProgress
                        variant='indeterminate'
                        color='primary'
                        thickness={5}
                        size={20}
                      />
                    )}
                    {error && <WarningRounded />}
                  </InputAdornment>
                ) : (
                  <InputAdornment>
                    {usernameLoading && (
                      <CircularProgress
                        variant='indeterminate'
                        color='primary'
                        thickness={5}
                        size={20}
                      />
                    )}
                    {status === 200 && (
                      <CheckCircle style={{ color: colors.green[500] }} />
                    )}
                    {status === 400 && <WarningRounded />}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} className={classes.spacing}>
            <PasswordField
              value={password}
              onChange={(value) => {
                setPassword(value);
                onGeneric({
                  createGeneric,
                  username,
                  password: value,
                });
              }}
            />
          </Grid>
        </>
      )}
      {createGeneric && student && (
        <Grid container alignItems='center' className={classes.caption}>
          <Typography variant='caption' color='secondary'>
            Edits to this Generic Student’s Username & Password can be completed
            in the Students section.
          </Typography>
        </Grid>
      )}
      {createGeneric && student && (
        <Alert severity='warning' className={classes.root}>
          <Grid container alignItems='center'>
            <Typography variant='caption' color='secondary'>
              When toggle is switched off, the Generic Student will be unlinked
              & their Account/Data will be Permanently Deleted.
            </Typography>
          </Grid>
        </Alert>
      )}
    </Grid>
  );
};

export default LinkedGeneric;
