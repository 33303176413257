/**
 * * Global Actions
 */

import { successAction, attemptAction, errorAction } from '../../constants';
import {
  SETALERT,
  SETSOUND,
  GLOBAL_ATTEMPT,
  GLOBAL_ERROR,
  GETPROFILE_SUCCESS,
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
  SHOWGLOBALDIALOG,
  HIDEGLOBALDIALOG,
} from '../constants';
import axios from '../apiCaller';

export const setAlert = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETALERT, data));
  };
  return thunk;
};

export const setSound = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETSOUND, data));
  };
  return thunk;
};

export const getProfile = () => {
  const thunk = async function thunk(dispatch) {
    try {
      const type = localStorage.getItem('loginType');
      const sound = localStorage.getItem('sound');
      if (!sound) {
        localStorage.setItem('sound', 'true');
      }
      dispatch(attemptAction(GLOBAL_ATTEMPT));
      const urlType = type === 'teacher' ? `user` : 'student';
      const body = await axios.get(urlType);
      const userData = body.data?.user;
      if (userData?.isLogOutRequired) {
        // log out if user is not verified
        setTimeout(() => {
          localStorage.clear();
          dispatch(
            enqueueSnackbar({
              message: 'Your session is expired, logging you out',
              options: {
                key: new Date().getTime() + Math.random(),
                variant: 'info',
              },
            })
          );
          window.location.href = '/';
        }, 2500);
      }
      dispatch(successAction(GETPROFILE_SUCCESS, { ...body.data, type }));
      if (userData?.resetLinkId) {
        if (urlType === 'student') {
          window.location.href = `/reset-student-password/${userData?._id}/${userData?.resetLinkId}`;
        } else {
          window.location.href = `/reset-password/${userData?._id}/${userData?.resetLinkId}`;
        }
      }
    } catch (err) {
      dispatch(errorAction(GLOBAL_ERROR, err));
    }
  };
  return thunk;
};

export const acceptAgreement = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GLOBAL_ATTEMPT));
      const body = await axios.post('user/accept-agreement');
      dispatch(
        successAction(GETPROFILE_SUCCESS, { ...body.data, type: 'teacher' })
      );
    } catch (err) {
      dispatch(errorAction(GLOBAL_ERROR, err));
    }
  };
  return thunk;
};

export const acceptWelcome= () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GLOBAL_ATTEMPT));
      const body = await axios.post('user/accept-welcome');
      dispatch(
        successAction(GETPROFILE_SUCCESS, { ...body.data, type: 'teacher' })
      );
    } catch (err) {
      dispatch(errorAction(GLOBAL_ERROR, err));
    }
  };
  return thunk;
};

export const enqueueAxiosError = (error) => {
  const message = error?.data?.message || 'Something went wrong';
  const result = enqueueSnackbar({
    message: message,
    options: {
      key: new Date().getTime() + Math.random(),
      variant: 'error',
    },
  });
  return result;
};

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = (key) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key) => ({
  type: REMOVE_SNACKBAR,
  key,
});

export const showGlobalDialog = (data) => ({
  type: SHOWGLOBALDIALOG,
  content: data,
});

export const hideGlobalDialog = () => ({
  type: HIDEGLOBALDIALOG,
});
