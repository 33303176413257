/**
 * * Reducer for Document Hub, follows the cases as per relevant actions
 */

import {
  VERIFYCODE_ATTEMPT,
  VERIFYCODE_SUCCESS,
  VERIFYCODE_FAIL,
  SETPWD_ATTEMPT,
  SETPWD_SUCCESS,
  SETPWD_FAIL,
  FORGOTPWD_ATTEMPT,
  FORGOTPWD_SUCCESS,
  FORGOTPWD_FAIL,
  REGISTER_ATTEMPT,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  SIGNIN_ATTEMPT,
  SIGNIN_SUCCESS,
  SIGNIN_FAIL,
  RESETLINK_ATTEMPT,
  RESETLINK_SUCCESS,
  RESETLINK_FAIL,
} from '../constants';

const initialState = {
  fetching: false,
  error: null,
  message: null,
  messageField: null,
  statusCode: null,
  forgotRequestCode: null,
  statusPassword: null,
  verifyStatus: null,
  token: null,
  linkLoading: false,
  linkStatus: null,
  linkMessage: null,
  userStatus: null,
  name: null,
  licenseStatus: null,
  isGeneric: false,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case VERIFYCODE_ATTEMPT:
      return {
        ...state,
        fetching: true,
        message: null,
        statusCode: null,
        verifyStatus: null,
      };
    case VERIFYCODE_SUCCESS:
      return {
        ...state,
        fetching: false,
        message: action.payload.message,
        statusCode: action.payload.status === 200 ? 200 : null,
        verifyStatus: action.payload.status,
      };
    case VERIFYCODE_FAIL:
      const verifyFailPayload = JSON.parse(action.payload)?.data;
      return {
        ...state,
        fetching: false,
        error: verifyFailPayload,
        message: verifyFailPayload?.message,
        statusCode: verifyFailPayload?.status,
      };
    case FORGOTPWD_ATTEMPT:
      return {
        ...state,
        fetching: true,
        message: null,
        forgotRequestCode: null,
      };
    case FORGOTPWD_SUCCESS:
      return {
        ...state,
        fetching: false,
        message: action.payload.message,
        forgotRequestCode: action.payload.status,
      };
    case FORGOTPWD_FAIL:
      return {
        ...state,
        fetching: false,
        error: action.payload,
        message: action.payload.message,
        forgotRequestCode: action.payload.status,
      };
    case SETPWD_ATTEMPT:
      return { ...state, fetching: true, message: null, statusPassword: null };
    case SETPWD_SUCCESS:
      if (action.payload.status === 200) {
        localStorage.setItem('token', action.payload.token);
      }
      return {
        ...state,
        fetching: false,
        message: action.payload.message,
        statusPassword: action.payload.status,
        statusCode: action.payload.status,
      };
    case SETPWD_FAIL:
      return {
        ...state,
        fetching: false,
        error: action.payload,
        message: action.payload.message,
        statusCode: action.payload.status,
      };
    case REGISTER_ATTEMPT:
      return { ...state, fetching: true };
    case REGISTER_SUCCESS:
      return {
        ...state,
        fetching: false,
        message: action.payload.message,
        status: action.payload.status,
      };
    case REGISTER_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case SIGNIN_ATTEMPT:
      return { ...state, fetching: true };
    case SIGNIN_SUCCESS:
      return {
        ...state,
        fetching: false,
        status: action.payload.status,
        message: action.payload.message,
        messageField: action.payload.field,
        licenseStatus: action.payload.licenseStatus || null,
        isGeneric: action.payload.isGeneric || false,
      };
    case SIGNIN_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case RESETLINK_ATTEMPT:
      return { ...state, linkLoading: true };
    case RESETLINK_SUCCESS:
      return {
        ...state,
        linkLoading: false,
        linkStatus: action.payload.status,
        linkMessage: action.payload.message,
        userStatus: action.payload.userStatus || null,
        name: action.payload.name || null,
      };
    case RESETLINK_FAIL:
      return {
        ...state,
        linkLoading: false,
        linkStatus: 'invalid',
        linkMessage: action.payload,
        userStatus: null,
        name: null,
      };
    default:
      return state;
  }
}
