import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllUsers,
  addUser,
  deleteUser,
  updateUser,
  sortStudents,
  archiveStudent,
  restoreStudent,
  bulkArchiveStudent,
  bulkRestoreStudent,
  bulkDeleteStudent,
} from '../../redux/actions/student';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Loader from '../../../../components/Loader';
import EnhancedTable from '../../components/Table';
import PageHeader from '../../components/PageHeader';
import DeleteDialog from 'components/DeleteDialog';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RestoreFromTrash from '@material-ui/icons/RestoreFromTrash';

import StudentDialog from 'containers/SuperAdmin/components/StudentDialog';
import FilterBar from 'containers/SuperAdmin/components/FilterBar';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import ImportStudentDialog from 'containers/SuperAdmin/components/ImportStudentsDialog';
import { enqueueSnackbar } from 'redux/actions';
import RestoreStudentDialog from 'containers/SuperAdmin/components/RestoreStudentDialog';
import { fetchLicenses } from 'containers/SuperAdmin/redux/actions/license';
import { disabledSchoolFields } from 'containers/SuperAdmin/constants';
import { useQuery } from 'constants';
import AccountStatus from 'components/AccountStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  add: {
    marginRight: theme.spacing(1),
  },
}));

let initialUser = {
  firstName: '',
  lastName: '',
  username: '',
  password: '',
  grade: '',
  school: '',
  teacher: '',
};

export default function Students({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { users, fetching } = useSelector(({ admin }) => admin.student);
  const { licenseData } = useSelector(({ admin }) => admin.license);

  const { teacher, grade, schoolId } = useParams();
  const isArchived = useQuery('isArchived');

  const [deleteId, setDeleteId] = useState(null);
  const [deleteIds, setDeleteIds] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [archiveId, setArchiveId] = useState(null);
  const [archiveIds, setArchiveIds] = useState([]);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);

  const [restoreId, setRestoreId] = useState(null);
  const [restoreIds, setRestoreIds] = useState([]);
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const [restoreUser, setRestoreUser] = useState(null);

  const [userData, setUserData] = useState(null);
  const [userDialog, setUserDialog] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [view, setView] = useState(isArchived ? 'archived' : 'normal');
  const [sort, setSort] = useState({ orderBy: 'fullName', orderDir: 'asc' });
  const [openDialog, setOpenDialog] = useState(false);
  const [school, setSchool] = useState('');
  const [updating, setUpdating] = useState(false);

  const isMatched = useRouteMatch(['/superadmin/teacher/:teacher/students'])

  const loadPage = (page, sortOptions = {}) => {
    let options = {
      page,
      limit: users.limit,
      ...sortOptions,
    };

    if (options.orderBy === 'fullName') {
      options.orderBy = 'name';
    }

    if (teacher) {
      options.teacher = teacher;
    }
    if (grade) {
      options.grade = grade;
    }
    if (schoolId) {
      options.school = schoolId;
    }
    if (view === 'archived') {
      options.isArchived = true;
    }
    dispatch(
      sortStudents(sortOptions?.orderBy, sortOptions?.orderDir, users?.docs)
    );
    setSort(sortOptions);
  };

  const handleAction = () => {
    let options = {
      page: users.page,
      limit: users.limit,
    };
    if (teacher) {
      options.teacher = teacher;
    }
    if (grade) {
      options.grade = grade;
    }
    if (schoolId) {
      options.school = schoolId;
    }
    if (school) {
      options.school = school;
    }
    if (view === 'normal') {
      options.isArchived = true;
      options.page = 1;
      options.limit = 25;
    }
    dispatch(fetchAllUsers(options));
    setView(view === 'normal' ? 'archived' : 'normal');
    if (isArchived) {
      if (teacher !== undefined && teacher !== null) {
        history.push('/superadmin/teachers/' + teacher + '/students');
      } else {
        history.push('/superadmin/students');
      }
    }
  };

  const handleSearch = (d) => {
    let options = { page: 1, limit: users.limit, search: d };
    if (teacher) {
      options.teacher = teacher;
    }
    if (grade) {
      options.grade = grade;
    }
    if (schoolId) {
      options.school = schoolId;
    }
    if (school) {
      options.school = school;
    }
    if (view === 'archived') {
      options.isArchived = true;
      options.page = 1;
      options.limit = 25;
    }
    dispatch(fetchAllUsers(options));
  };

  const handleSchool = (d) => {
    setSchool(d);
    let options = { page: 1, limit: users.limit };
    if (d) {
      options.school = d;
    }
    if (teacher) {
      options.teacher = teacher;
    }
    if (grade) {
      options.grade = grade;
    }
    if (view === 'archived') {
      options.isArchived = true;
      options.page = 1;
      options.limit = 25;
    }
    dispatch(fetchAllUsers(options));
  };

  const headCells = [
    { id: 'checkbox', label: 'Select' },
    { id: 'fullName', label: 'Name' },
    { id: 'username', label: 'Username' },
    {
      id: `licenseType`,
      label: 'School',
    },
    {
      id: 'className',
      label: 'Class',
    },
    {
      id: 'teacherName',
      label: 'Teacher',
    },
    {
      id: 'status',
      label: 'Account Status',
      align: 'center',
      formatter: (row) => <AccountStatus status={row.status} />,
    },
    {
      id: 'actions',
      label: 'Actions',
      actions:
        view === 'normal'
          ? [
              {
                label: 'Edit',
                action: (r) => {
                  setEditUser(true);
                  let userEditData = {
                    ...r,
                    teacher: r.teacher ? r.teacher?._id : '',
                    grade: r.grade ? r.grade?._id : '',
                    school: r.school ? r.school?._id : '',
                  };
                  setUserData(userEditData);
                  setUserDialog(true);
                },
                icon: <EditIcon />,
                disabled: () => false,
              },
              {
                label: 'Archive',
                action: (r) => {
                  if (!r.school) {
                    dispatch(
                      enqueueSnackbar({
                        message:
                          'Generic Students cannot be Archived, only Permanently Deleted. To Delete, switch the Generic Student toggle off in the relevant Individual Teacher’s Edit License box.',
                        options: {
                          key: new Date().getTime() + Math.random(),
                          variant: 'error',
                        },
                      })
                    );
                  } else {
                    setArchiveId(r._id);
                    setOpenArchiveDialog(true);
                  }
                },
                icon: <ArchiveIcon />,
                disabled: () => false,
              },
            ]
          : [
              {
                label: 'Restore',
                action: (r) => {
                  if (!r.school) {
                    dispatch(
                      enqueueSnackbar({
                        message:
                          'You are attempting to restore an Individual Student. You can perform this action only from Licenses Page.',
                        options: {
                          key: new Date().getTime() + Math.random(),
                          variant: 'error',
                        },
                      })
                    );
                  } else {
                    const license = licenseData?.find(
                      (x) => x.licensee === r.school?._id
                    );
                    if (
                      license?.isArchived &&
                      license?.status === 'Deactivated'
                    ) {
                      dispatch(
                        enqueueSnackbar({
                          message:
                            'Student(s) cannot be restored because the License they belong to is inactive. Please reactivate their License first.',
                          options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                          },
                        })
                      );
                    } else if (
                      license?.consumedStudentLicenses ===
                      license?.studentLicenses
                    ) {
                      dispatch(
                        enqueueSnackbar({
                          message:
                            'The active Student Pool of this License is full. Please Archive active Students or allot space for additional Student Accounts within the License',
                          options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                          },
                        })
                      );
                    } else if (
                      license?.consumedStudentLicenses + 1 >
                      license?.studentLicenses
                    ) {
                      dispatch(
                        enqueueSnackbar({
                          message:
                            'The active Student Pool of this License is full. Please archive 1 active student or allot space for 1 additional Student account within the License',
                          options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                          },
                        })
                      );
                    } else {
                      setRestoreId(r._id);
                      setRestoreUser(r);
                      setOpenRestoreDialog(true);
                    }
                  }
                },
                icon: <RestoreFromTrash />,
                disabled: () => false,
              },
              {
                label: 'Permanently Delete',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <DeleteForeverIcon />,
                disabled: (r) => r.isSuperAdmin,
              },
            ],
    },
  ];

  const onConfirmStudent = (data, emailTrigger) => {
    if (editUser) {
      setUpdating(true);
      const id = userData?._id;
      let update = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        username: data?.username,
        email: data?.email,
        school: data?.school,
        status: data?.status,
        homeAddress: data?.homeAddress,
        phoneNumber: data?.phoneNumber,
        schoolName: data?.schoolName,
        schoolAddress: data?.schoolAddress,
        emailTrigger,
        teacher: data?.teacher,
        grade: data?.grade,
      };
      if (data.password !== '') {
        update.password = data.password;
      }
      dispatch(
        updateUser(id, update, () => {
          setUserDialog(false);
          setUpdating(false);
          setUserData(initialUser);
        })
      );
    } else {
      dispatch(addUser(data));
      setUserData(initialUser);
      setUserDialog(false);
    }
  };

  useEffect(() => {
    dispatch(fetchLicenses());
    if (teacher !== undefined || teacher !== null) {
      dispatch(fetchAllUsers({ teacher, isArchived }));
      return;
    } else {
      dispatch(fetchAllUsers({ isArchived }));
    }
    if (isArchived) {
      setView('archived');
    }
  }, [dispatch, teacher, isArchived]);

  return (
    <div className={classes.root}>
      {fetching && <Loader fetching={fetching} />}
      <PageHeader
        title={name}
        options={
          <>
            <IconButton
              onClick={() => {
                setEditUser(false);
                setUserData(initialUser);
                setUserDialog(true);
              }}
              className={classes.add}
            >
              <AddIcon />
            </IconButton>
            <Button
              onClick={() => setOpenDialog(true)}
              variant='contained'
              color='primary'
            >
              Bulk Import
            </Button>
          </>
        }
        onClearFilter={() => {
          if (teacher !== undefined && teacher !== null) {
            if (isMatched?.isExact) {
              history.push('/superadmin/schools?tab=1');
            } else history.push(
              `/superadmin/teachers${
                view === 'archived' ? '?isArchived=true' : ''
              }`
            );
          } else {
            history.push('/superadmin/teachers');
          }
        }}
      />
      <FilterBar
        enableTopics={false}
        enableGlobal
        selectedPage='Students'
        onSearch={handleSearch}
        onAction={handleAction}
        toggle={view === 'archived'}
        enableSchool
        selectedSchool={school}
        onSchoolSelect={handleSchool}
      />
      {users && users.docs.length > 0 && (
        <Grid container direction='row' justifyContent='flex-start' spacing={2}>
          <Grid item sm={12} xs={12}>
            <EnhancedTable
              data={{
                ...users,
                docs: users.docs.filter((x) =>
                  view === 'normal' ? !x.isArchived : x.isArchived
                ),
                totalDocs: users.docs.filter((x) =>
                  view === 'normal' ? !x.isArchived : x.isArchived
                ).length,
              }}
              view={view}
              headCells={headCells}
              handlePage={loadPage}
              orderField={sort.orderBy}
              orderDirection={sort.orderDir}
              handleOrder={(d) => loadPage(users.page, d)}
              standardPagination={true}
              handleBulk={(data) => {
                const ids = data?.map((d) => d._id);
                setArchiveId(null);
                setRestoreId(null);
                if (view === 'normal') {
                  setArchiveIds(ids);
                  setOpenArchiveDialog(true);
                } else {
                  const restoreData = data[0];
                  const license = licenseData?.find(
                    (x) => x.licensee === restoreData?.school?._id
                  );
                  if (
                    license?.isArchived &&
                    license?.status === 'Deactivated'
                  ) {
                    dispatch(
                      enqueueSnackbar({
                        message:
                          'Student(s) cannot be restored because the License they belong to is inactive. Please reactivate their License first.',
                        options: {
                          key: new Date().getTime() + Math.random(),
                          variant: 'error',
                        },
                      })
                    );
                  } else if (
                    license?.consumedStudentLicenses ===
                    license?.studentLicenses
                  ) {
                    dispatch(
                      enqueueSnackbar({
                        message:
                          'The active Student Pool of this License is full. Please Archive active Students or allot space for additional Student Accounts within the License',
                        options: {
                          key: new Date().getTime() + Math.random(),
                          variant: 'error',
                        },
                      })
                    );
                  } else if (
                    license?.consumedStudentLicenses + ids?.length >
                    license?.studentLicenses
                  ) {
                    const additionalSpacesRequired =
                      ids?.length -
                      (license?.studentLicenses -
                        license?.consumedStudentLicenses);
                    dispatch(
                      enqueueSnackbar({
                        message: `You are attempting to unarchive ${ids?.length} student(s). Please archive ${additionalSpacesRequired} active student(s) or allot space for ${additionalSpacesRequired} additional Student account(s) within the License`,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          variant: 'error',
                        },
                      })
                    );
                  } else {
                    setRestoreIds(ids);
                    setRestoreUser(restoreData);
                    setOpenRestoreDialog(true);
                  }
                }
              }}
              handleBulkDelete={(data) => {
                const ids = data?.map((d) => d._id);
                setDeleteId(null);
                setDeleteIds(ids);
                setOpenDeleteDialog(true);
              }}
            />
          </Grid>
        </Grid>
      )}
      {openDialog && (
        <ImportStudentDialog
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
        />
      )}
      {userData && (
        <StudentDialog
          open={userDialog}
          edit={editUser}
          data={userData}
          suffix='to an Active Group License'
          disableDone={true}
          disabledFields={
            !editUser
              ? disabledSchoolFields
              : userData?.school
              ? disabledSchoolFields
              : []
          }
          handleClose={() => {
            setUserDialog(false);
            setUserData(initialUser);
          }}
          handleConfirm={onConfirmStudent}
          sending={updating}
        />
      )}
      <DeleteDialog
        open={openArchiveDialog}
        content={
          'Are you sure you want to archive student(s)? You can restore them at any time from the Archive Folder, as long as their License is Active.'
        }
        handleClose={() => {
          setOpenArchiveDialog(false);
          setArchiveId(null);
          setArchiveIds([]);
        }}
        handleConfirm={() => {
          if (archiveId && archiveIds?.length === 0) {
            dispatch(archiveStudent(archiveId));
            setArchiveId(null);
          } else {
            dispatch(bulkArchiveStudent(archiveIds));
            setArchiveIds([]);
          }
          setOpenArchiveDialog(false);
        }}
        confirmLabel={'Yes'}
        cancelLabel={'No'}
      />
      <DeleteDialog
        open={openDeleteDialog}
        content={
          'Warning! Are you sure you want to permanently delete this student(s) & all of their records? This action can’t be undone.'
        }
        handleClose={() => {
          setOpenDeleteDialog(false);
          setDeleteId(null);
          setDeleteIds([]);
        }}
        handleConfirm={() => {
          if (deleteId && deleteIds?.length === 0) {
            dispatch(deleteUser(deleteId));
            setDeleteId(null);
          } else {
            dispatch(bulkDeleteStudent(deleteIds));
            setDeleteIds([]);
          }
          setOpenDeleteDialog(false);
        }}
        confirmLabel={'Yes'}
        cancelLabel={'No'}
      />
      <RestoreStudentDialog
        open={openRestoreDialog}
        handleClose={() => {
          setOpenRestoreDialog(false);
          setRestoreId(null);
          setRestoreIds([]);
          setRestoreUser(null);
        }}
        handleConfirm={(data) => {
          if (restoreId && restoreIds.length === 0) {
            dispatch(restoreStudent(restoreId, data));
          } else {
            dispatch(
              bulkRestoreStudent(restoreIds, data?.grade, data?.teacher)
            );
          }
          setOpenRestoreDialog(false);
          setRestoreId(null);
          setRestoreIds([]);
          setRestoreUser(null);
        }}
        school={restoreUser?.school?._id}
      />
    </div>
  );
}
