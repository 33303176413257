import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  SCHOOLACTION_ATTEMPT,
  SCHOOLLIST_ATTEMPT,
  SCHOOL_ERROR,
  FETCHALLSCHOOLS_SUCCESS,
  FETCHALLINDIVIDUALSCHOOLS_SUCCESS,
  FETCHALLARCHIVEDSCHOOLS_SUCCESS,
  FETCHSCHOOL_SUCCESS,
  ADDSCHOOL_SUCCESS,
  UPDATESCHOOL_SUCCESS,
  UPDATEINDIVIDUALSCHOOL_SUCCESS,
  DELETESCHOOL_SUCCESS,
  ADDHEADTEACHER_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';

const schoolUrl = 'admin/schools';
const userUrl = 'admin/users';
export const fetchAllSchools = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(SCHOOLLIST_ATTEMPT));
      const body = await axios.get(`${schoolUrl}`, { params });
      dispatch(successAction(FETCHALLSCHOOLS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(SCHOOL_ERROR, err));
    }
  };
  return thunk;
};

export const fetchAllIndividualTeacherSchools = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(SCHOOLLIST_ATTEMPT));
      const body = await axios.get(`${schoolUrl}/individual-teacher-schools`, { params });
      dispatch(successAction(FETCHALLINDIVIDUALSCHOOLS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(SCHOOL_ERROR, err));
    }
  };
  return thunk;
};

export const fetchAllArchivedSchools = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(SCHOOLLIST_ATTEMPT));
      const body = await axios.get(`${schoolUrl}/archived`, { params });
      dispatch(successAction(FETCHALLARCHIVEDSCHOOLS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(SCHOOL_ERROR, err));
    }
  };
  return thunk;
};

export const fetchSchool = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(SCHOOLLIST_ATTEMPT));
      const body = await axios.get(`${schoolUrl}/${id}`);
      dispatch(successAction(FETCHSCHOOL_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(SCHOOL_ERROR, err));
    }
  };
  return thunk;
};

export const addSchool = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(SCHOOLACTION_ATTEMPT));
      const body = await axios.post(`${schoolUrl}`, data);
      dispatch(successAction(ADDSCHOOL_SUCCESS, body.data));
      if (body.data.headTeacher && body.data.headTeacher._id) {
        dispatch(fetchAllSchools());
        dispatch(
          successAction(ADDHEADTEACHER_SUCCESS, { user: body.data.headTeacher })
        );
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(SCHOOL_ERROR, err));
    }
  };
  return thunk;
};

export const updateSchool = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(SCHOOLACTION_ATTEMPT));
      const body = await axios.post(`${schoolUrl}/${id}`, data);
      dispatch(successAction(UPDATESCHOOL_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(SCHOOL_ERROR, err));
    }
  };
  return thunk;
};
export const updateIndividualSchool = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(SCHOOLACTION_ATTEMPT));
      const body = await axios.post(`${userUrl}/${id}`, data);
      dispatch(successAction(UPDATEINDIVIDUALSCHOOL_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(SCHOOL_ERROR, err));
    }
  };
  return thunk;
};

export const deleteSchool = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(SCHOOLACTION_ATTEMPT));
      const body = await axios.delete(`${schoolUrl}/${id}`);
      dispatch(successAction(DELETESCHOOL_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(SCHOOL_ERROR, err));
    }
  };
  return thunk;
};
