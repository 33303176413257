import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import {
  makeStyles
} from '@material-ui/core';
import { COLORS } from 'constants';
import { green } from '@material-ui/core/colors';
const useStyles = makeStyles((theme) => ({
  spacing: {
    margin: theme.spacing(1, 0),
  },
  doubleSpacing: {
    margin: theme.spacing(2, 0),
  },
  label: {
    margin: theme.spacing(0, 0),
  },
  actions: {
    justifyContent: 'space-between',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  validity: {
    root: {
      width: '75%',
    },
  },
  iconSelected: {
    color: green[500],
  },
  text: {
    flexGrow: 1,
  },
}));

export default function EmailDialog({ open, handleClose, html, title }) {
  const styles = useStyles();

  

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
      <DialogTitle align='center' style={{ color: COLORS.PINK }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12} sm={12} container direction="row" spacing={2} className={styles.spacing}>
            <div
              style={{
                textAlign: 'justify',
                transform: 'scaleX(1.1)',
                transformOrigin: 'left',
                maxWidth: '92%',
                paddingRight: '10px',
              }}
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
            </Grid>
          </Grid>
          </DialogContent>
      </Dialog>
    </>
  );
}
