import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { Info } from '@material-ui/icons';
import UserDialog from '../UserDialog';
import { Divider } from '@material-ui/core';

let initialTeacherData = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  access: 'teacher',
  accessLevel: 'full',
  isVerified: true,
  isHeadTeacher: true,
};

export default function SchoolDialog({
  open,
  handleClose,
  handleConfirm,
  data,
  edit = false,
  headTeacherData = null,
}) {
  const [form, setForm] = useState(data || { name: '', address: '' });
  const [headTeacherDialog, setHeadTeacherDialog] = useState(false);
  const [teacherData, setTeacherData] = useState(headTeacherData || initialTeacherData);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (!open) return;
    if (data) {
      setForm(data || { name: '', address: '' });
    }
    if (headTeacherData) {
      setTeacherData(headTeacherData || initialTeacherData);
    } else {
      setTeacherData(null);
    }
  }, [open, edit, data, headTeacherData]);

  useEffect(() => {
    setIsFormValid(
      form?.name?.trim() !== '' &&
      form?.address?.trim() !== ''
    );
  }, [form]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      maxWidth='sm'
    >
      <DialogTitle id='form-dialog-title'>School</DialogTitle>
      <DialogContent>
        <Grid container direction='column' spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              autoComplete='name'
              name='Name'
              variant='outlined'
              required
              fullWidth
              id='name'
              label='Name'
              autoFocus
              value={form.name}
              onChange={(e) => setForm({ ...form, name: e.target.value })}
              error={!form?.name?.trim()}
              helperText={!form?.name?.trim() && "Name is required"}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              variant='outlined'
              required
              fullWidth
              id='address'
              label='Address'
              name='address'
              autoComplete='address'
              value={form.address}
              onChange={(e) => setForm({ ...form, address: e.target.value })}
              error={!form?.address?.trim()}
              helperText={!form?.address?.trim() && "Address is required"}
            />
          </Grid>
          {!edit && (
            <Grid item xs={12} sm={12}>
              <Alert variant='filled' color='warning' icon={<Info />}>
                Please click below to add Head Teacher, this will be responsible
                for managing their school and its licenses whenever a license is
                created
              </Alert>
            </Grid>
          )}
          {!edit && (
            <Grid item xs={12} sm={6}>
              <Button
                variant='outlined'
                onClick={() => setHeadTeacherDialog(true)}
              >
                {teacherData ? 'Change Head Teacher' : 'Create Head Teacher'}
              </Button>
              <UserDialog
                open={headTeacherDialog}
                handleClose={() => setHeadTeacherDialog(false)}
                data={initialTeacherData}
                showSubtitle={false}
                prefix={'Head'}
                handleConfirm={(data) => {
                  setTeacherData(data);
                  setHeadTeacherDialog(false);
                }}
                disabledFields={[
                  'access',
                  'accessLevel',
                  'school',
                  'isHeadTeacher',
                  'isVerified',
                  'password',
                  'sendManual',
                ]}
              />
            </Grid>
          )}
          <Divider />
          {teacherData && (
            <Grid item xs={12} sm={12} container spacing={2}>
              <Grid item xs={12} sm={12} container direction={'row'}>
                <Typography variant='h5'>Head Teacher</Typography>
              </Grid>
              <Grid item xs={12} sm={12} container direction={'row'}>
                <Grid item xs={12} sm={3}>
                  <Typography>Name</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {[teacherData?.firstName, teacherData?.lastName].join(' ')}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} container direction={'row'}>
                <Grid item xs={12} sm={3}>
                  <Typography>Email</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {teacherData?.email}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={() =>
            handleConfirm({ school: form, headTeacher: teacherData })
          }
          color='primary'
          variant={'contained'}
          disabled={
            !teacherData ||
            teacherData?.firstName === '' ||
            teacherData?.lastName === '' ||
            teacherData?.email === '' ||
            form?.name === '' ||
            !isFormValid
          }
        >
          {edit ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
