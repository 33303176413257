import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import {
  Button,
  Checkbox,
  Collapse,
  TableFooter,
  TableHead,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { COLORS } from '../../constants';
import { PinkButton } from 'components/Buttons';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
  },
  table: ({ tableWidth }) => ({
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    minWidth: tableWidth || '90vw',
    maxHeight: '70vh',
  }),
  container: {
    width: '100%',
    scrollBehavior: 'smooth',
    padding: theme.spacing(1),
    maxHeight: '60vh',
    overflowX: 'auto',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.35em',
      height: 15,
      padding: 15,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 4px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 4px rgba(0,0,0,0.00)',
      backgroundColor: COLORS.DARKPURPLE,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      background: 'rgb(222,204,248)',
    },
  },
  tableBody: {},
  head: {
    fontWeight: 'bold',
    color: COLORS.PRIMARY,
    '& .MuiTableSortLabel-active': {
      color: COLORS.SECONDARY,
    },

  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  row: {
    backgroundColor: COLORS.BG,
  },
  footer: {
    width: '100%',
    padding: theme.spacing(2),
  },
  spacing: {
    margin: theme.spacing(3, 0),
    columnGap: 2,
  },
  hideScrollBar: {
    overflowX: 'hidden !important',
    width: '100%',
    padding: theme.spacing(1),
    maxHeight: '60vh',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  }
}));

export default function EnhancedTable({
  size = 'small',
  headCells,
  data,
  enableFooter = false,
  footer,
  tableWidth = '90vw',
  orderBy,
  orderDir,
  onSort,
  onBulkArchive,
  onBulkUnarchive,
  onBulkDelete,
  bulkVariant = 1,
  selectedTitle,
  selected,
  setSelected,
  scroll = true,
}) {
  const classes = useStyles({ tableWidth });

  const handleSelected = (row) => {
    const selectedIndex = selected?.indexOf(row);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected?.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => id === selected?._id;

  const createSortHandler = (property) => (event) => {
    event.preventDefault();
    if (onSort) {
      onSort(property);
    }
  };

  return (
    <TableContainer className={ scroll ? classes.container : classes.hideScrollBar}>
      <Collapse in={ selected?.length > 0 }>
        <Grid
          container
          justifyContent='flex-end'
          className={ classes.spacing }
          alignItems='center'
        >
          { selected?.length > 0 && (
            <Grid item xs={ 12 } sm={ 6 } md={ 2 }>
              <Typography color='primary' variant='h6'>
                { selected?.length } { selectedTitle }
                { selected?.length > 1 ? 's' : '' } Selected
              </Typography>
            </Grid>
          ) }
          { selected?.length > 0 && bulkVariant === 1 && (
            <Grid item xs={ 12 } sm={ 6 } md={ 2 }>
              <Button
                variant='contained'
                color='primary'
                onClick={ () => {
                  setSelected(data?.map((x) => x._id));
                } }
              >
                Select All
              </Button>
            </Grid>
          ) }
          { onBulkArchive && (
            <Button
              variant='contained'
              color='primary'
              onClick={ () => {
                onBulkArchive(selected);
              } }
            >
              Bulk Archive
            </Button>
          ) }
          { onBulkUnarchive && bulkVariant === 1 && (
            <Button
              variant='contained'
              color='primary'
              onClick={ () => {
                onBulkUnarchive(selected);
              } }
            >
              Bulk Unarchive
            </Button>
          ) }
          { onBulkDelete && bulkVariant === 1 && (
            <Button
              variant='contained'
              color='primary'
              onClick={ () => {
                onBulkDelete(selected);
              } }
            >
              Bulk Delete
            </Button>
          ) }
          { bulkVariant === 1 && (
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={ () => {
                  setSelected([]);
                } }
              >
                Clear Selections
              </Button>
            </Grid>
          ) }
          { selected?.length > 0 && bulkVariant === 2 && (
            <Grid item xs={ 12 } sm={ 6 } md={ 2 }>
              <PinkButton
                customStyles={ {
                  height: 55,
                  width: 50,
                } }
                handleClick={ () => {
                  setSelected(data?.map((x) => x._id));
                } }
              >
                Select All
              </PinkButton>
            </Grid>
          ) }
          { onBulkUnarchive && bulkVariant === 2 && (
            <Grid item>
              <PinkButton
                customStyles={ {
                  height: 55,
                  width: 50,
                } }
                handleClick={ () => {
                  onBulkUnarchive(selected);
                } }
              >
                Bulk Unarchive
              </PinkButton>
            </Grid>
          ) }
          { onBulkDelete && bulkVariant === 2 && (
            <Grid item>
              <PinkButton
                customStyles={ {
                  height: 55,
                  width: 50,
                } }
                handleClick={ () => {
                  onBulkDelete(selected);
                } }
              >
                Bulk Delete
              </PinkButton>
            </Grid>
          ) }
          { bulkVariant === 2 && (
            <Grid item>
              <PinkButton
                customStyles={ {
                  height: 55,
                  width: 50,
                } }
                handleClick={ () => {
                  setSelected([]);
                } }
              >
                Clear Selections
              </PinkButton>
            </Grid>
          ) }
        </Grid>
      </Collapse>
      <Table
        className={ classes.table }
        size={ size }
        aria-label='Standard Table'
        aria-labelledby='Standard Table'
      >
        <TableHead>
          <TableRow>
            { headCells.map((headCell) => {
              if (headCell.id === 'checkbox') {
                return (
                  <TableCell padding='checkbox' key={ headCell.id }>
                    <Checkbox
                      indeterminate={
                        selected?.length > 0 && selected?.length < data?.length
                      }
                      checked={
                        data?.length > 0 && selected?.length === data?.length
                      }
                      onChange={ () => {
                        data?.length === selected?.length
                          ? setSelected([])
                          : setSelected(data?.map((x) => x._id));
                      } }
                      inputProps={ { 'aria-label': 'select all items' } }
                    />
                  </TableCell>
                );
              }
              return (
                <TableCell
                  key={ headCell.id }
                  align={ headCell.align ? headCell.align : 'left' }
                  padding={ 'checkbox' }
                  className={ classes.head }
                  style={ headCell.styles }
                  colSpan={ headCell?.colSpan }
                >
                  { headCell.id !== 'actions' ? (
                    <TableSortLabel
                      active={ orderBy === headCell.id }
                      direction={ orderBy === headCell.id ? orderDir : 'asc' }
                      onClick={ createSortHandler(headCell.id) }
                    >
                      { headCell.label }
                      { orderBy === headCell.id ? (
                        <span className={ classes.visuallyHidden }>
                          { orderDir === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending' }
                        </span>
                      ) : null }
                    </TableSortLabel>
                  ) : (
                    headCell.label
                  ) }
                </TableCell>
              );
            }) }
          </TableRow>
        </TableHead>
        <TableBody className={ classes.tableBody }>
          { data.length > 0 &&
            data.map((row, index) => {
              return (
                <TableRow key={ index } selected={ isSelected(row._id) }>
                  { headCells.map((headCell) => {
                    if (headCell.id === 'checkbox') {
                      return (
                        <TableCell
                          align={ headCell.align ? headCell.align : 'left' }
                          key={ `${row._id}-${headCell.id}` }
                          padding='checkbox'
                          onClick={ (r) => handleSelected(row._id) }
                        >
                          <Checkbox
                            checked={ selected.indexOf(row._id) !== -1 }
                            inputProps={ { 'aria-label': 'select item' } }
                          />
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        align={ headCell.align ? headCell.align : 'center' }
                        key={ `${headCell.id}-${index}` }
                        style={ {
                          color: COLORS.PRIMARY,
                          cursor: 'default',
                          ...headCell.styles,
                        } }
                        className={ classes.row }
                        padding={ 'normal' }
                        colSpan={ headCell?.colSpan }
                      >
                        { headCell.formatter
                          ? headCell.formatter(row, index)
                          : row[headCell.id] }
                      </TableCell>
                    );
                  }) }
                </TableRow>
              );
            }) }
        </TableBody>
        { enableFooter && (
          <TableFooter>
            <Grid container className={ classes.footer }>
              { footer }
            </Grid>
          </TableFooter>
        ) }
      </Table>
    </TableContainer>
  );
}
